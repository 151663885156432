/*eslint-disable */
// import QuestionResultModel from '../../Model/QuestionResult'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let AppResults = {
  /**
   * appResultList
   */
  async appResultList (context, appResultObj) {
    try{
      let post_data = new FormData();

      for (let key in appResultObj) {
        if (appResultObj[key]) {
          post_data.append(key, appResultObj[key]);
        }
      }
      return await request.curl(context, "apps_results_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at appResultList() and Exception:',err.message)
    }
  },

  /**
   * appResultAllAverage
   */
  async appResultAllAverage (context, appResultObj) {
    try{
      let post_data = new FormData();

      for (let key in appResultObj) {
        if (appResultObj[key]) {
          post_data.append(key, appResultObj[key]);
        }
      }
      return await request.curl(context, "apps_all_results_average", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at appResultAllAverage() and Exception:',err.message)
    }
  },

  /**
   * appResultAllCategoryAverage
   */
  async appResultAllCategoryAverage (context, appResultObj) {
    try{
      let post_data = new FormData();

      for (let key in appResultObj) {
        if (appResultObj[key]) {
          post_data.append(key, appResultObj[key]);
        }
      }
      return await request.curl(context, "apps_all_results_category_average", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at appResultAllAverage() and Exception:',err.message)
    }
  },
  /**
   * appResultConsoleList
   *
   */
  async appResultConsoleList (context, appResultObj, filter  = {}) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";
      let post_data = new FormData();

      for (let key in appResultObj) {
        if (appResultObj[key]) {
          post_data.append(key, appResultObj[key]);
        }
      }

      if (filter) {
        post_data.append('filter', JSON.stringify(filter));
      }

      return await request.curl(context, "apps_results_console_list", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at appResultList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * appResultSync
   */
  async appResultSync (context, appId) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 

    try{
      let post_data = new FormData();
      post_data.append("app_id", appId)
  
      return await request.curl(context, "apps_results_percentile_sync", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at appResultList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * appResultLeaderBoard
   */
   async appResultLeaderBoard (context, appId, limit = null, offset = 0) {
    try{
      let post_data = new FormData();
      post_data.append("app_id", appId)
      post_data.append("offset", offset)

      if (limit) {
        post_data.append("limit", limit)
      }

      return await request.curl(context, "apps_results_leader_board", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at appResultList() and Exception:',err.message)
    }
  },
  /**
   * appResultView
   */
  async appResultView (context, appResultObj) {
    try {      
      let post_data = new FormData();
      for (let key in appResultObj) {
        if (appResultObj[key] || typeof appResultObj[key] == 'number') {
          post_data.append(key, appResultObj[key]);
        }
      }

      return await request.curl(context, "apps_results_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at appResultView() and Exception:",err.message)
    }    
  },

  /**
   * appResultAdd
   */
  async appResultAdd (context, appResultObj) {
    try{
      let post_data = new FormData();
    
      for (let key in appResultObj) {
        post_data.append(key, appResultObj[key]);
      }

      return await request.curl(context, "apps_results_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appResultAdd() and Exception:',err.message)
    }
  },

  /**
   * appResultEdit
   */
  async appResultEdit (context, appResultObj) {
    try{
      let post_data = new FormData();
    
      for (let key in appResultObj) {
        post_data.append(key, appResultObj[key]);
      }

      return await request.curl(context, "apps_results_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at appResultEdit() and Exception:',err.message)
    }
  },

  /**
   * appResultDelete
   */
  async appResultDelete (context, appResultId) {
    try{
      let post_data = new FormData();
      
      post_data.append('aprs_id', appResultId);

      return await request.curl(context, "apps_results_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at appResultDelete() and Exception:',err.message)
    }
  },

  /**
   * gptResultInsights
   */
  async gptResultInsights(context, chainItem, result_owner_id) {
    try{
      let post_data = new FormData();
      
      post_data.append('result_owner_id', result_owner_id);
      post_data.append('qbkCat', JSON.stringify(chainItem.qbkCat));
      post_data.append('app_id', chainItem.app_id);
      post_data.append('aprs_id', chainItem.aprs_id);

      return await request.curl(context, "apps_gpt_results_insights", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gptResultInsights() and Exception:',err.message)
    }
  }
}

export {
  AppResults
}
