<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="appAdd()">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col sm="6" md="6" lg="3" xl="3" class="mb-2">
                <!-- Backend Search -->
                <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText"
                /><!-- Backend Search -->
              </b-col>
              <b-col sm="6" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.quiz_type" class="form-control"  style="text-transform: capitalize;" @change="setQuizTypeFilter()">
                  <option  v-for="(quiz_type, index) of QuizType" :key="(index+1)" :value="index" >
                    {{quiz_type}}
                  </option>
                </select>
              </b-col>
              <b-col sm="6" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.event_state" class="form-control" @change="setEventStateFilter()">
                  <option :value="99">All</option>
                  <option :value="0">Publish</option>
                  <option :value="2">Unpublish</option>
                  <option :value="1">Delete</option>
                </select>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="quizObjList && quizObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="quizObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(app_name)="data">
                    {{data.item.app_name}}<br>
                    <b-button variant="mr-1 mb-1 " @click="openApp(data.item)" title="View App"><i class="fa fa-eye font-size-20" aria-hidden="true" ></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="syncResult(data.item)" title="Sync Result"><i class="fa fa-refresh font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="quizBankList(data.item)" title="Question Bank" v-if="!data.item.editable"><i class="fa fa-file-text-o font-size-20"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="appResultList(data.item)" title="Results"><i class="fa fa-star font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="appEdit(data.item)" title="App Edit" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0 font-size-20" title="Edit"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="showQuizDeleteDialog(data.item)" title="Delete"><i class="ri-delete-bin-line m-0 font-size-20" title="Delete"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="doCopy(data.item.app_id)" title="Copy App Id"><i class="fa fa-copy font-size-20" aria-hidden="true"></i></b-button>
                    <b-button v-if="data.item.app_type == QuizType.COMPETITION" variant=" mr-1 mb-1 " @click="openEventDetail(data.item)" title="View Event Detail"><i class="fa fa-calendar font-size-20" aria-hidden="true" ></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="solveQuizBankViaGPT(data.item)" title="Solve Quiz"><i class="fa fa-quora font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " @click="deleteQuizQuestions(data.item)" title="Delete Quiz Questions Before Re-upload"><i class="fa fa-ban font-size-20" aria-hidden="true"></i></b-button>
                    <b-button variant=" mr-1 mb-1 " v-if="userData.user_role == 'USERROLE11111'" @click="attemptQuizForTesting(data.item)" title="Attempt Quiz For Testing"><i class="fa fa-flask font-size-20" aria-hidden="true"></i></b-button>
                  </template>

                  <template v-slot:cell(deleted)="data">
                    <b-badge v-if="data.item.deleted === 0" class="pointer ml-1" variant="success mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Published&nbsp;
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Unpublished
                    </b-badge>
                    <b-badge v-if="data.item.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm" @click="openUrlInNew(data.item.event_weblink)">
                      Deleted
                    </b-badge>

                    <b-button variant="light mr-1 mb-1" title="Unpublish"  :disabled="data.item.deleted === 2" @click="appPublishUnpublish(data.item)">
                      <i class="fa fa-toggle-off m-0 font-size-20" style="color:red"></i>
                    </b-button>
                    <b-button variant="light mr-1 mb-1" title="Publish" :disabled="data.item.deleted === 0" @click="appPublishUnpublish(data.item)">
                      <i class="fa fa-toggle-on m-0 font-size-20" style="color:green"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(app_date_ts)="data">
                   {{data.item.app_date_ts | dateFormat}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModelQuizDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelQuizDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="appDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Apps } from "../../../FackApi/api/apps.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { AppResults } from "../../../FackApi/api/appResults.js"
import QuizType from "../../../FackApi/json/QuizType.js"
import userPermission from "../../../Utils/user_permission.js"
import { socialvue } from "../../../config/pluginInit"
import { QuizBanks } from "../../../FackApi/api/quizBank.js"

export default {
  name: "AppList",
  data () {
    return {
      apiName: "app_list_console",
      cvCardTitle: "Apps - Quiz, Competition, Games",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type To Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are You Sure?",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "App List Response",
      showModelQuizDelete: false,
      sortBy: "name",
      sortDesc: false,
      QuizType: QuizType,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "app_name", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Status ", key: "deleted", class: "text-left align-text-top", sortable: true },
        { label: "Type", key: "app_type", class: "text-left align-text-top", sortable: true },
        { label: "Category", key: "app_category", class: "text-left align-text-top", sortable: true },
        { label: "Date", key: "app_date_ts", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Duration (Sec)", key: "app_duration", class: "text-left align-text-top", sortable: true },
        { label: "Is Gideplus?", key: "app_is_gideplus", class: "text-left align-text-top", sortable: true },
        { label: "Total Questions", key: "app_total_questions", class: "text-left align-text-top", sortable: true },
        { label: "Is Paid", key: "app_is_paid", class: "text-left align-text-top", sortable: true }
      ],
      quizObjList: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      whereFilter: {
        search_param: "",
        quiz_type: "QUIZ",
        event_state: 99 // All Apps
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    eventState () {
      return this.$store.getters["EventState/selectedEventState"]
    },
    quizType () {
      return this.$store.getters["QuizType/selectedQuizTypeState"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getFiltersFromCache()
    this.QuizType = QuizType(this.userData.user_role)
    this.appList()
  },
  methods: {
    /**
    * get data from store cache by this method
    */
    async getFiltersFromCache () {
      await this.getEventStateFilter()
      await this.getQuizTypeFilter()
    },
    /**
     * openApp
     */
    openApp (app) {
      window.open(`/quiz_detail/${app.app_id}`)
    },
    /**
     * openEventDetail
     */
    openEventDetail (app) {
      window.open((`/event/${app.app_id}`), "_blank")
    },
    /**
     * syncResult
     */
    async syncResult (examItem) {
      const resultSyncResp = await AppResults.appResultSync(this, examItem.app_id)
      if (!resultSyncResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, resultSyncResp)
      }
    },
    /**
     * Calling Function as per whereFilter
     */
    async setEventStateFilter () {
      this.$store.commit("EventState/addEventState", this.whereFilter.event_state)
      this.appList()
    },
    /**
     * Get Event State Filter
     * Upcoming, Past
     */
    async getEventStateFilter () {
      let EventState = await this.eventState
      if (EventState) {
        this.whereFilter.event_state = EventState
      }
      else if (EventState === 0) {
        this.whereFilter.event_state = EventState
      }
    },
    /**
     * Get Event State Filter
     * Upcoming, Past
     */
    async getQuizTypeFilter () {
      let QuizType = await this.quizType
      if (QuizType) {
        this.whereFilter.quiz_type = QuizType
      }
    },
    /**
     * Calling Function as per whereFilter
     */
    async setQuizTypeFilter () {
      this.$store.commit("QuizType/addQuizType", this.whereFilter.quiz_type)
      this.appList()
    },
    /**
     * quizBankList
     */
    async quizBankList (exam) {
      // this.$router.push(`/quizBank_list/${exam.app_id}`)
      window.open(`/quizBank_list/${exam.app_id}`, "_blank")
    },
    /**
     * appResultList
     */
    appResultList (app) {
      window.open(`/appResult_list/${app.app_id}`)
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }

      this.currentBatchNo = 1
      await this.appList()
    },
    /**
      * appList
      */
    async appList (loadViaLoadMore = false) {
      try {
        let quizListResp = await Apps.appListConsole(this, this.whereFilter)
        if (quizListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.quizObjList = [...quizListResp.resp_data.data]
            this.totalRows = quizListResp.resp_data.count
          }
          else {
            this.quizObjList = quizListResp.resp_data.data
          }

          this.totalRows = quizListResp.resp_data.count
        }
        else {
          this.toastMsg = quizListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at appList() and Exception:", err.message)
      }
    },
    /**
     * appAdd
     */
    appAdd () {
      window.open("/app_add")
    },
    /**
     * appEdit
     */
    appEdit (quizEditObj) {
      window.open("/quiz_edit/" + quizEditObj.app_id)
    },
    /**
     * showQuizDeleteDialog
     */
    showQuizDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelQuizDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showQuizDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * appDelete
     */
    async appDelete () {
      try {
        let quizDelResp = await Apps.appDelete(this, this.delObj.app_id)
        await ApiResponse.responseMessageDisplay(this, quizDelResp)

        if (quizDelResp && !quizDelResp) {
          this.showModelQuizDelete = false
          return false
        }

        let index = this.quizObjList.indexOf(this.delObj)
        this.quizObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelQuizDelete = false
      }
      catch (err) {
        console.error("Exception occurred at appDelete() and Exception:", err.message)
      }
    },
    /**
     * deleteQuizQuestions
     */
    async deleteQuizQuestions (appDetails) {
      try {
        const deleteObj = {}
        if (appDetails.app_type == "test_prep" || appDetails.app_type == "competition" || appDetails.app_type == "counselling") {
          deleteObj.app_id = appDetails.app_id
        }
        else {
          deleteObj.app_category = appDetails.app_category
        }
        const bulDeleteResp = await QuizBanks.quizBankBulkDelete(this, deleteObj)
        ApiResponse.responseMessageDisplay(this, bulDeleteResp)
      }
      catch (err) {
        console.error("Exception in deleteQuizQuestions and err: ", err)
      }
    },
    /**
     * attemptQuizForTesting
     */
    async attemptQuizForTesting (appDetails) {
      try {
        const attempQuizObj = {
          user_id: this.userData.user_id,
          gide_user_id: this.userData.user_id,
          app_id: appDetails.app_id
        }
        const attemptQuizResp = await QuizBanks.appsAutomaticResultGenerate(this, attempQuizObj)
        ApiResponse.responseMessageDisplay(this, attemptQuizResp)
      }
      catch (err) {
        console.error("Exception in attemptQuizForTesting and err: ", err)
      }
    },
    /**
     * closeQuizEditModal
     */
    closeQuizEditModal () {
      try {
        this.showModelQuizEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeQuizEditModal() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * appPublishUnpublish
     */
    async appPublishUnpublish (data) {
      let payload = {
        app_id: data.app_id,
        deleted: null,
        image: data.app_image,
        desc: data.app_description,
        title: data.app_name,
        app_type: data.app_type,
        app_date_ts: data.app_date_ts,
        app_duration: data.app_duration
      }

      if (data.deleted === 0) {
        // event is published then unpublish
        payload.deleted = 2
      }
      else if (data.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      const questionResp = await Apps.appPublishUnpublish(this, payload)
      if (questionResp && questionResp.resp_status) {
        data.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, questionResp)
      }
    },
    /**
     * solveQuizBankViaGPT
     */
    async solveQuizBankViaGPT (appData) {
      try {
        const solveObj = {
          app_id: appData.app_id,
          app_category: appData.app_category,
          app_type: appData.app_type,
          app_total_questions: appData.app_total_questions
        }
        let quizSolveResp = await QuizBanks.quizBankSolve(this, solveObj)

        if (!quizSolveResp.resp_status) {
          this.toastMsg = quizSolveResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        this.toastMsg = quizSolveResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
      }
      catch (err) {
        console.error("Exception in solveQuizBankViaGPT and err: ", err)
      }
    }
  }
}
</script>
